import { BaseItem } from './HomePage.model';
import { get } from './RepoService';


export function getHomePageDetails(page: number = 1, items: number = 15) {
    return get<BaseItem>(`/GetHomepageDetails/${page}/${items}`);
}

export function getTrendingDetails(): Promise<{ topics: BaseItem[], questions: BaseItem[] }> {
    return get<BaseItem>(`/GetTrendingDetails`)
        .then(response => response.result);
}

export function getDailyTrendingDetailsTopic(): Promise<{ Topic: BaseItem[] }> {
    return get<BaseItem[]>(`/getDailyTrendingItem/Topic/1/10`)
        .then(response => response.result);
}

export function getDailyTrendingDetailsQuestion(): Promise<{ Question: BaseItem[] }> {
    return get<BaseItem[]>(`/getDailyTrendingItem/Question/1/10`)
        .then(response => response.result);
}

export function getHashtags() {
    return get(`/getTenDistinctHashtags`)
        .then(response => response.result);
}

export function getHashtagDetails(id:string){
    return get(`/getDailyTrendingItemByHashtag/${id}/1/10`)
        .then(response => response.result);
}

export function getSearchResults(key: string): Promise<{ result: BaseItem[] }> {
    return get<BaseItem>(`/HomeSearch/${key}`).then(response => response);
}

export function getSearchStockResults(key: string): Promise<{ result: BaseItem[] }> {
    return get<BaseItem>(`/getDailyTrendingItemByHashtag/${key}/1/10`).then(response => response);
}