import React, { useEffect, useState } from 'react'
import { getSearchStockResults } from '../../services/HomePage.service';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function StockInfo({ state, setState }: any) {
    const [searchElement, setSearchElement] = useState(false);
    const [items, setItems] = React.useState({ result: [] });
    const [search, setSearch] = React.useState('');
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        (async () => {
            try {

                setSearch(window.location.search.split('=')[1]);
                console.log(search);
                const searchItems = await getSearchStockResults(search.toString());
                setSearchElement(true);
                setItems(searchItems);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [items, search])

    return (
        <div className="row justify-content-center">
            <div className="col-md-10 col-12 mx-atuo">
                <div className="sj-standalone-item-container">
                    {searchElement && <>
                        {items?.result?.length > 0 && <>
                            <p className="h3 mt-4">Stock Insights for <p style={{ backgroundColor: 'yellow', display: 'inline-block' }}>{search}</p> </p>
                            <br />
                            {/* Previous search results like universal search on app 
                            <div> 
                                {items.result?.map((item) => (
                                    <div>
                                        <div className="d-flex pb-1">
                                            <div className="mx-1">
                                                <Avatar size={"25"} round={true} textSizeRatio={1} name={item.postedby} maxInitials={2}></Avatar>
                                            </div>
                                            <div className="mx-1">
                                                <div className="d-flex flex-column">
                                                    <a href={`item/${item.type}/${item.id}/${kebabCase(item.title)}`.toLocaleLowerCase()}>
                                                        <h6 className="fw-bold mt-1">
                                                            {item.title?.replace(/\n/g, ' ').substring(0, 100)}
                                                        </h6>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="mb-1 trending-item-summary">
                                                        {item.summary?.replace(/\n/g, ' ').substring(0, 150)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}

                            {/* <div>
                                {items.result?.map((item) => (
                                    <div>
                                        <div className="d-flex pb-1">
                                            <div className="mx-1">
                                                <div style={{ display: 'inline' }}>
                                                    <b>Title: {renderHTML(item.title)}</b>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Summary:</b> {renderHTML(item.summary)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}

                            <div>
                                <Tabs defaultActiveKey={items[0]?.id} id="uncontrolled-tab-example" className="mb-1">
                                    {items.result?.map((item) => (
                                        <Tab eventKey={item.id} title={item.title} key={item.id}>
                                            <div className="p-3">
                                                <p> {renderHTML(item.summary)} </p>
                                            </div>
                                        </Tab>
                                    ))}
                                </Tabs>
                            </div>
                        </>
                        }
                    </>

                    }
                    {!searchElement && <h3>Loading...</h3>}
                </div>
            </div>
        </div>
    )
}

export default StockInfo
